import { Injectable, OnDestroy } from '@angular/core';

import { distinctUntilChanged, filter, skip, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';

// import { Crisp } from 'crisp-sdk-web';
import { EnvConfig } from '../constants';
import { AdminModeService } from './mode.service';
import { Account, PersonalHostProfile, TeamMemberHostProfile, User, UserMetadataStateModel, UserStateModel } from '../models';

@Injectable({ providedIn: 'root' })
export class SupportService implements OnDestroy {
    private authenticated: boolean;
    private readonly websiteId: string = EnvConfig.crispWebsiteId;
    private readonly destroySource: Subject<void> = new Subject<void>();

    constructor(private readonly modeService: AdminModeService) {
        // if (EnvConfig.inProduction) {
        //     Crisp.configure(this.websiteId);
        //     Crisp.load();
        // }
    }

    public ngOnDestroy(): void {
        this.destroySource.next();
        this.destroySource.complete();
    }

    public init(): void {
        // if (!this.authenticated) {
        //     combineLatest([
        //         this.modeService.user$,
        //         this.modeService.account$,
        //         this.modeService.userMetaData$,
        //     ]).pipe(
        //         filter((value: [
        //             User | null,
        //             Account,
        //             UserMetadataStateModel | null
        //         ]): value is [User, Account, UserMetadataStateModel] => {
        //             return !!value[0] && !!value[1] && !!value[2]?.crispProfileId;
        //         }),
        //         takeUntil(this.destroySource)
        //     ).subscribe(([user, account, userMetaData]: [User, Account, UserMetadataStateModel]): void => {
        //         this.auth(user, account, userMetaData.crispProfileId!);
        //     });
        //
        //     this.modeService.user$.pipe(
        //         filter((user: UserStateModel | null): user is UserStateModel => !!user?.id),
        //         skip(1),
        //         distinctUntilChanged((x: UserStateModel, y: UserStateModel) => {
        //             return (x && y && JSON.stringify(x) === JSON.stringify(y));
        //         }),
        //         takeUntil(this.destroySource)
        //     ).subscribe(() => {
        //         this.SendUserEvents([{ title: 'user__changed', data: null }]);
        //     });
        // }
    }

    public logout(): void {
        /**
         * Resets the chatbox session to a new session
         * "reload" controls whether to reload page upon reset or not;
         * by default the page is not reloaded upon reset as this is not needed.
         */
        // const reload: boolean = true;
        // Crisp.session.reset(reload);
    }

    public showCrispWidget(): void {
        // if (EnvConfig.inProduction) {
        //     Crisp.chat.show();
        // }
    }

    public hideCrispWidget(): void {
        // if (EnvConfig.inProduction) {
        //     Crisp.chat.hide();
        // }
    }

    public SendUserEvents(events: Array<{ title: string; data: object | null }>): void {
        if (events.length) {
            events.forEach((event: { title: string; data: object | null }) => this.SendEvent(event));
        }
    }

    public SendSessionData(payload: object): void {
        // if (EnvConfig.inProduction) {
        //     Crisp.session.setData(payload);
        // }
    }

    private SendEvent(event: { title: string; data: object | null }): void {
        // if (EnvConfig.inProduction && false) {
        //     Crisp.session.pushEvent(event.title, event.data ?? undefined);
        // }
    }

    private auth(user: User, account: Account, crispProfileId: string): void {
        // Crisp.setTokenId(crispProfileId);
        // Crisp.user.setEmail(user.email, user.crispEmailHash);
        // Crisp.user.setNickname(user.FullName);
        //
        // if ((account instanceof PersonalHostProfile || account instanceof TeamMemberHostProfile) && account.phone) {
        //     Crisp.user.setPhone(account.phone);
        // }
        //
        // if ((account instanceof PersonalHostProfile || account instanceof TeamMemberHostProfile) && account.avatar) {
        //     Crisp.user.setAvatar(account.avatar);
        // }
        //
        // this.authenticated = true;
    }
}
